/* HomeScreen Styles */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

.grid-images {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px;
    padding: 20px;
}

.grid-images img {
    max-width: 100%;
    height: auto;
    cursor: pointer;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    padding: 15%; /* 15% margin around the modal */
    box-sizing: border-box;
}

.modal-image-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-image {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
    border-radius: 10px;
}

.icon-container {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    gap: 10px;
}

.icon {
    cursor: pointer;
    font-size: 24px;
}

.icon-close {
    color: red;
}

/* Mobile grid styling */
@media (max-width: 768px) {
    .grid-images {
        grid-template-columns: repeat(3, 1fr); /* 3 images per row */
    }
}

.search-form {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.search-form label {
    display: block;
    margin-bottom: 10px;
    color: #333;
}

.search-form input[type=date] {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.search-form button {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: 15px;
    background-color: #0056b3;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.search-form button:hover {
    background-color: #004494;
}

.grid-images img {
    cursor: pointer;
    /* Add any additional styles for the images if needed */
}

