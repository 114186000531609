.videos-container ul {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Flexible grid */
    gap: 20px;
    list-style: none;
    padding: 0;
    margin: 0;
}

.videos-container li {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.video-thumbnail {
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden; /* Ensures content stays within the rounded border */
    transition: transform 0.3s, box-shadow 0.3s;
    background-color: #f9f9f9;
    display: flex; /* Aligns children properly */
    align-items: center;
    justify-content: center;
}

.video-thumbnail img {
    width: 100%; /* Ensure the image fills the container */
    height: 100%; /* Ensures the container's height is respected */
    object-fit: cover; /* Scales and crops the image proportionally */
    display: block;
}

.video-thumbnail:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.video-info {
    padding: 10px;
    background: #fff;
}

.video-info h4 {
    font-size: 1rem;
    margin: 0;
    color: #333;
}

.video-info p {
    margin: 5px 0 0;
    font-size: 0.9rem;
    color: #666;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 10px;
}

.pagination button {
    padding: 8px 12px;
    font-size: 16px;
    cursor: pointer;
}

.pagination button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
