.notes-container ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* creates 3 columns */
    gap: 20px; /* space between the grid items */
    list-style: none; /* removes bullet points */
    padding: 0; /* removes padding */
    margin: 0; /* removes margin */
}

.notes-container li {
    display: flex; /* centers the button in the grid cell */
    justify-content: center; /* centers horizontally */
    align-items: center; /* centers vertically */
}

.albumButton {
    /* Remove width: 100%; to let the button size fit its content */
    padding: 5px 10px; /* optional: adds some padding inside the button */
    /* Add any additional styling you need for the buttons */
}

.albumButton.blue {
    background-color: #add8e6; /* Light blue */
    color: white; /* Adjust text color as needed */
}

.albumButton.pink {
    background-color: #ffb6c1; /* Light pink */
    color: black; /* Adjust text color as needed */
}

.albumButton.green {
    background-color: #90ee90; /* Light green */
    color: white; /* Adjust text color as needed */
}

.albumButton.defaultColor {
    background-color: #d3d3d3; /* Light gray */
    color: white; /* Adjust text color as needed */
}

