
@media (max-width: 768px) {
    .navigation .icon {
        display: none;
    }
}

.fullscreen-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: black;
    position: relative;  /* Required for the navigation arrows to position correctly */
}

.fullscreen-image-container img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}

.navigation {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    pointer-events: none; /* Ensures that clicks/taps go through this layer */
}

.navigation .icon {
    pointer-events: auto; /* Re-enables click/tap on the arrow icons */
}

.close-button {
    position: absolute;
    top: 10px; /* Adjust this value to move the button lower if it's too high */
    right: 10px; /* Adjust this value to move the button more to the left if it's too far to the right */
    background: transparent;
    border: none;
    color: white; /* Adjust as per your color scheme */
    font-size: 24px; /* Adjust size as needed */
    cursor: pointer;
    z-index: 10; /* Ensure it's above other elements */
}

/* Style for hover effect (optional) */
.close-button:hover {
    color: #ddd;
}

.image-date, .image-description {
    position: absolute;
    left: 50px; /* Adjust based on your layout */
    color: white;
    z-index: 5; /* Ensure it's above the image but below the close button */
}

.image-date {
    top: 10px; /* Adjust as needed */
    font-size: 18px; /* Adjust size as needed */
}

.image-description {
    top: 40px; /* Positioned below the date, adjust as needed */
    font-size: 16px; /* Adjust size as needed */
    width: 80%; /* Adjust width as needed */
}
