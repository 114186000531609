/* HomeScreen Styles */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

.grid-images {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px;
    padding: 20px;
}

.grid-images img {
    max-width: 100%;
    height: auto;
    cursor: pointer;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    padding: 15%; /* 15% margin around the modal */
    box-sizing: border-box;
}

.modal-image-container {
    position: relative;
    width: 80%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 10px;
}

.icon-container {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    gap: 10px;
}

.icon {
    cursor: pointer;
    font-size: 24px;
}

.icon-close {
    color: red;
}

/* Mobile grid styling */
@media (max-width: 768px) {
    .grid-images {
        grid-template-columns: repeat(3, 1fr); /* 3 images per row */
    }
}

.icon.arrow-left,
.icon.arrow-right {
    cursor: pointer;
    font-size: 24px;
    color: #fff; /* white color for better visibility on darker backgrounds */
    margin: 0 20px; /* added margin for better spacing */
}


.navigation {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
}
