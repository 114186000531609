.images-container.single img {
    width: 100%;
    height: auto;
}

.images-container.multiple {
    display: flex;
    justify-content: space-between;
}

.images-container.multiple img {
    flex: 1;
    margin: 0 5px;
    max-width: 100%;
    height: auto;
}

.navigation-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.prev-note-button, .next-note-button {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
}

.prev-note-button:hover, .next-note-button:hover {
    background-color: #e0e0e0;
}
