.video-page-container {
    display: flex;
    flex-direction: row; /* Aligns children in a row */
    height: 100vh; /* Full viewport height */
    margin: 0;
    padding: 0;
    align-items: center; /* Aligns children vertically */
}

.video-container {
    flex: 0 0 80%; /* Takes up 80% width, no grow, no shrink */
    max-height: 90vh; /* Adjusts video height */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5vh 0; /* Adds vertical margin */
}

video {
    max-height: 90vh; /* Limits video height */
    width: auto; /* Maintains aspect ratio */
    max-width: 100%; /* Ensures video does not exceed container width */
}

.form-container {
    flex: 0 0 20%; /* Takes up 20% width, no grow, no shrink */
    max-height: 90vh;
    overflow-y: auto; /* Allows scrolling inside the form if needed */
    padding: 10px;
}

/* Responsive design for mobile devices */
@media (max-width: 768px) {
    .video-page-container {
        flex-direction: column; /* Stacks children vertically */
    }

    .video-container {
        flex: 1 0 100%; /* Takes up full width */
    }

    .form-container {
        display: none; /* Hides the form */
    }
}

.share-button-container {
    text-align: center;
    margin-bottom: 20px; /* Adjust as needed */
}
