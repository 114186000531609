.navbar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #2c3e50;
    padding: 10px;
}

.nav-button {
    background-color: #3498db;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.nav-button:hover {
    background-color: #2980b9;
}

/* Adjusted or removed media query */
@media (max-width: 400px) { /* Example: change to a smaller width */
    .nav-button {
        padding: 10px 10px; /* Adjust padding for smaller screens */
    }
}
