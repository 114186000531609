/* StatScreen.css */

.stat-screen {
    font-family: 'Arial', sans-serif;
    color: #333;
    margin: 0;
    padding: 20px;
    background-color: #f4f7fa;
    min-height: 100vh;
}

.stat-title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 20px;
    color: #2a3d66;
    font-weight: bold;
}

.error-message {
    text-align: center;
    color: #ff5c5c;
    font-size: 1.1rem;
    margin-top: 20px;
}

.locations-container {
    display: flex;
    justify-content: space-around;
    gap: 30px;
    flex-wrap: wrap;
    margin-top: 20px;
}

.locations-box {
    width: 45%;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #ddd;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
}

.locations-title {
    font-size: 1.5rem;
    margin-bottom: 15px;
    color: #2a3d66;
    font-weight: bold;
    text-align: center;
}

.locations-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.location-item {
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
}

.location-box {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 12px;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    font-size: 1.1rem;
}

.location-box:hover {
    background-color: #f1f1f1;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.count {
    font-weight: bold;
    color: #2a3d66;
    font-size: 1.2rem;
}

@media (max-width: 768px) {
    .stat-title {
        font-size: 1.6rem;
    }

    .locations-box {
        width: 100%;
    }

    .location-box {
        font-size: 1rem;
        padding: 10px;
    }

    .count {
        font-size: 1.1rem;
    }
}
