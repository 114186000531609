img {
    width: 100%;
    height: 100vh;
    object-fit: contain;
    max-width: 60%;
}

.form-container {
    padding: 20px;
    background-color: #f6f6f6;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.form-container input[type="text"],
.form-container input[type="date"],
.form-container textarea {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 16px;
}

.form-container button {
    padding: 10px 20px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.form-container button:hover {
    background-color: #0056b3;
}

.banner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    color: white;
    text-align: center;
    padding: 10px;
    z-index: 1000; /* ensure it's above other elements */
    transition: opacity 0.5s;
}

.banner.success {
    background: green;
}

.banner.failure {
    background: red;
}

.tags-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 20px;
    width: 300px;
}

.tag-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f5f5f5;
    padding: 5px 10px;
    margin-bottom: 5px;
}

.remove-tag {
    background-color: black;
    color: white;
    padding: 2px 5px;
    cursor: pointer;
    margin-left: 10px;
}

.add-tag {
    background-color: green;
    color: white;
    padding: 5px 10px;
    cursor: pointer;
}

.share-button {
    background-color: green;
    color: white;
    padding: 5px 10px;
    cursor: pointer;
}

.un-share-button {
    background-color: red;
    color: white;
    padding: 5px 10px;
    cursor: pointer;
}

.add-tag-modal-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.add-tag-modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 300px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.error-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-family: Arial, sans-serif;
    background-color: #f7f7f7;
}

.error-page h2 {
    font-size: 3rem;
    color: #d9534f;  /* A subtle shade of red */
    margin-bottom: 1rem;
}

.error-page p {
    font-size: 1.2rem;
    color: #666;  /* A subtle shade of gray */
    max-width: 500px;
    text-align: center;
}

.home-button {
    padding: 10px 20px;
    font-size: 1.2rem;
    background-color: #007BFF;
    border: none;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;

    border-radius: 4px;
    margin-top: 20px;
}

.home-button:hover {
    background-color: #0056b3;
}